"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadPaypalScript = void 0;
var loadPaypalScript = function (clientId) {
    return new Promise(function (resolve, reject) {
        var script = document.createElement('script');
        script.src = "https://www.paypal.com/sdk/js?client-id=".concat(clientId);
        script.onload = function () { return resolve(); };
        script.onerror = function () { return reject(new Error('PayPal SDK could not be loaded.')); };
        document.head.appendChild(script);
    });
};
exports.loadPaypalScript = loadPaypalScript;
