"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    name: 'LanguageSelection',
    data: function () {
        return {
            language: 'de',
        };
    },
    methods: {
        submitLanguage: function () {
            this.$i18n.locale = this.language;
            this.$router.push('/criteria');
        },
    },
});
