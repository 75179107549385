"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vuex_1 = require("vuex");
exports.default = (0, vuex_1.createStore)({
    state: {
        username: ''
    },
    mutations: {
        setUsername: function (state, username) {
            state.username = username;
        }
    },
    actions: {
        updateUsername: function (_a, username) {
            var commit = _a.commit;
            commit('setUsername', username);
        }
    },
    getters: {
        getUsername: function (state) { return state.username; },
        isAuthenticated: function (state) { return !!state.username; } // Fügt den Getter isAuthenticated hinzu
    }
});
